header {
    background: #05386B;
    padding: 12px;
    display: flex;
    justify-content: space-between;
}

header h1 {
    font-size: 21px;
    font-weight: 200;
}

.search {
    display: flex;
}

.search input {
    width: 13em;
    margin-right: 5px;
}