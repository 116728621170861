button, .btn {
    border: 0;
    background: #03045e;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 1px 35px;
    border-radius: 5px;
    line-height: 22px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.15s ease;
}

button:hover, .btn:hover {
    transform: scale(1.10);

}