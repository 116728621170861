.home-page-btn {
    border: 0;
    background: transparent;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.8rem;
    padding: 1px 5px;
    border-radius: 5px;
    line-height: 22px;
    text-decoration: none;
    transition: all 0.15s ease;
}

.home-page-btn:hover {
    transform: scale(1.10);
}